import Vue from 'vue'
import responseMixin from '@/mixins/responseMixin'
import { mapGetters } from 'vuex'
import { FREELANCERS_LIST, FREELANCERS_LIST_BY_SKILL } from '@/constants/routes'
import FreelancerCard from '@/partials/FreelancerCards/FreelancerCard/FreelancerCard.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  components: {
    FreelancerCard,
  },
  data () {
    return {
      FREELANCERS_LIST_BY_SKILL,
      swiper: null,
    }
  },
  computed: {
    ...mapGetters({
      moreVacancies: 'vacancyDetails/moreVacancies',
      vacancy: 'vacancyDetails/vacancy',
      isLoaded: 'vacancyDetails/isLoaded',
    }),
    freelancers () {
      return this.vacancy.relatedFreelancers
    },
    categoryUrl () {
      return this.vacancy.category?.url
    },
    firstSkillUrl () {
      const skills = this.vacancy.relations?.Skill
      return (skills && skills.length > 0) && skills[0].url
    },
    moreTalentsUrl () {
      const skillUrl = this.categoryUrl || this.firstSkillUrl
      return skillUrl
        ? { name: FREELANCERS_LIST_BY_SKILL, params: { skill: skillUrl } }
        : { name: FREELANCERS_LIST }
    }
  },
  watch: {
    isLoaded: {
      handler () {
        if (this.isLoaded) {
          this.$nextTick(async () => {
            // @ts-ignore
            const Swiper = (await import(/* webpackChunkName: "swiper" */ 'swiper')).default
            const ref = 'freelancersContainer'
            if (this.$refs[ref]) {
              const swiper = new Swiper(this.$refs[ref], {
                slidesPerView: 'auto',
                spaceBetween: 32,
                freeMode: true,
                navigation: {
                  nextEl: this.$refs[`${ref}NextBtn`].$el,
                  prevEl: this.$refs[`${ref}PrevBtn`].$el,
                },
              })
              swiper.allowTouchMove = false
              this.swiper = swiper
            }
          })
        }
      },
      immediate: true,
    },
  },
})
