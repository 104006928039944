import Vue from 'vue'
import isEqual from 'lodash/isEqual'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import Job from '@/models-ts/job/Job'
import animateScrollTo from '@/utils/animateScrollTo'
import responseMixin from '@/mixins/responseMixin'
import { JOB_ADD, JOB_EDIT, JOB_DETAILS_ADMIN, POST_A_JOB } from '@/constants/routes'
import { Roles } from '@/constants/user/roles'
import { STATUS_PUBLISHED } from '@/constants/job/jobStatuses'
import snackMixin from '@/mixins/snackMixin'
import { createJob, editJob, getJob, publishJob } from '@/api/jobs/job'
import { linkedInAnalytics, googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { parseSlug } from '@/utils/parser'
import { withCommas } from '@/utils/moneyFormat'
import Skill from '@/models-ts/Skill'
import skillableMixin from '@/mixins/skillableMixin'
import { STAGE_NEW } from '@/constants/job/jobStages'
import JobBudget from './JobBudget/JobBudget.vue'
import JobDeadline from './JobDeadline/JobDeadline.vue'
import JobDescription from './JobDescription/JobDescription.vue'
import JobFiles from './JobFiles/JobFiles.vue'
import JobTitle from './JobTitle/JobTitle.vue'
import JobSkills from './JobSkills/JobSkills.vue'
import { FieldError } from 'vee-validate'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, skillableMixin, snackMixin],
  components: {
    JobBudget,
    JobDeadline,
    JobDescription,
    JobFiles,
    JobTitle,
    JobSkills,
  },
  data () {
    return {
      POST_A_JOB,
      job: new Job(),
      oldJob: new Job(),
      errorFields: [],
      preloading: true,
      updating: false,
      saving: false,
      publishing: false,
      readOnly: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      customerIsNewbie: (state: any) => state.user?.customerIsNewbie,
    }),
    ...mapGetters({
      exampleJobId: 'jobDetails/jobId',
      exampleJob: 'jobDetails/job',
    }),
    isPublished () {
      return this.job.status === STATUS_PUBLISHED
    },
    isEdit () {
      return this.$route.name === JOB_EDIT
    },
    pageTitle () {
      return this.isEdit ? 'Edit Job' : 'Create New Job'
    },
    processing () {
      return this.saving || this.publishing || this.updating
    },
    isUpdated () {
      return !isEqual(this.job, this.oldJob)
    },
    disabledSubmit () {
      return !this.isUpdated || this.processing || this.preloading || !this.skillsLoaded
    },
    files: {
      get () {
        return this.job?.relations?.File || []
      },
      set (v) {
        this.job.relations.File = v
      }
    },
  },
  watch: {
    '$route.name': {
      async handler () {
        try {
          if (this.$route.name === JOB_ADD) {
            this.job = new Job()
            this.oldJob = new Job()
            const editor: any = document.querySelector('.ck-editor__editable')
            editor && editor.ckeditorInstance.setData('')
          } else if (this.$route.name === JOB_EDIT) {
            this.preloading = true
            const job = await getJob(this.$route.params.id)
            this.job = Job.fromServer(job)
            this.oldJob = Job.fromServer(job)
            this.preloading = false
          }
        } catch (e) {
          if (ErrorMatcher.isNotFound(e)) {
            this.setNotFound(true)
          } else {
            this.parseError(e)
          }
        }
      },
    },
    predefinedSkills (newVal: any, oldVal: any) {
      if (!oldVal?.length && newVal?.length) {
        this.initializeSkills()
      }
    },
  },
  async created () {
    if (this.$route.query.fromType === 'job') {
      googleAnalyticsV2.send({
        event: 'add-job-from-another-job',
      })
    } else if (this.$route.query.fromType === 'profile') {
      googleAnalyticsV2.send({
        event: 'add-job-from-profile',
      })
    }
    try {
      if (this.$route.params.id) {
        const job = await getJob(this.$route.params.id)
        this.job = Job.fromServer(job)
        if (this.job.stage !== STAGE_NEW) {
          this.readOnly = true
        }
        if (!this.job.isNotStarted && this.$route.params.id) {
          this.$router.replace({ name: JOB_DETAILS_ADMIN, params: { id: this.$route.params.id, slug: this.job.slug } })
          return
        }
        this.oldJob = Job.fromServer(job)
      } else {
        this.job = new Job()
        this.oldJob = new Job()
        this.initializeSkills()
      }
      this.preloading = false
    } catch (e) {
      console.log(e)
      if (ErrorMatcher.isNotFound(e)) {
        this.setNotFound(true)
      } else {
        this.parseError(e)
      }
    }
  },
  beforeDestroy () {
    if (!this.exampleJobId) {
      this.clearJob()
    }
  },
  methods: {
    withCommas,
    ...mapMutations({
      clearJob: 'jobDetails/clearJob',
      setNotFound: 'app/setNotFound',
      setCustomerIsNewbie: 'user/setCustomerIsNewbie',
    }),
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    initializeSkills () {
      if (this.$route.query.jobId &&
        String(this.exampleJob?.id) === String(this.$route.query.jobId) &&
        this.predefinedSkills.length) {
        this.job.relations.Skill = (this.exampleJob?.relations?.Skill || []).map((s: Skill) =>
          this.predefinedSkills.find((skill: Skill) => s.id === skill.id))
      }
    },
    async validateAll (publishing: boolean) {
      let isValid = true
      const fields = ['jobTitle', 'jobSkills', 'jobDescription', 'jobBudget']
      this.errorFields = []
      for (const field of fields) {
        const el = this.$refs[field]
        if (el?.validate) {
          const error = await el.validate(publishing) as FieldError
          if (!error) {
            isValid = false
            this.errorFields.push(field)
          }
        }
      }
      return isValid
    },
    async onClickSave (publishing: Boolean) {
      if (await this.validateAll(publishing)) {
        try {
          this.saving = true
          await this.saveJob()
          this.$router.push({ name: JOB_DETAILS_ADMIN, params: { id: this.job.id, slug: this.job.slug } })
        } finally {
          this.saving = false
        }
      } else {
        this.validateFail()
      }
    },
    async onClickPublish () {
      if (await this.validateAll(true)) {
        await this.saveAndPublishJob()
        googleAnalyticsV2.send({
          event: 'job-published',
          job_id: this.job.id,
        })
        linkedInAnalytics.sendJobPublished()
        this.$router.push({ name: JOB_DETAILS_ADMIN, params: { id: this.job.id, slug: this.job.slug } })
      } else {
        this.validateFail()
      }
    },
    async onClickUpdate () {
      if (await this.validateAll()) {
        await this.updateJob()
        this.$router.push({ name: JOB_DETAILS_ADMIN, params: { id: this.job.id, slug: this.job.slug } })
      } else {
        this.validateFail()
      }
    },
    async onClickSignUp () {
      if (await this.validateAll(true)) {
        try {
          this.saving = true
          this.signupAndPublish()
        } finally {
          this.saving = false
        }
      } else {
        this.validateFail()
      }
    },
    validateFail () {
      if (this.errorFields.length) {
        animateScrollTo(this.$refs[this.errorFields[0]]?.$el)
      }
      const analyticParams = {
        event: 'job-button-publish-failed',
        'event-content': '',
        category: this.errorFields.join(',')
      }
      if (this.$route.query.fromType === 'job') {
        analyticParams['event-content'] = 'add-job-from-another-job'
      } else if (this.$route.query.fromType === 'profile') {
        analyticParams['event-content'] = 'add-job-from-profile'
      }
      if (this.isEdit) {
        // @ts-ignore
        analyticParams.job_id = this.job.id
      }
      googleAnalyticsV2.send(analyticParams)
    },
    async saveJob () {
      try {
        if (this.job.id) {
          const { slug } = await editJob(this.job.id, this.job.toServer())
          this.job.slug = parseSlug(slug)
          this.openSnackbar({
            text: `You have successfully updated Job: ${this.job.name}.`,
          })
        } else {
          const { id, slug, moderation_stage } = await createJob(this.job.toServer())
          this.job.moderation_stage = moderation_stage
          if (this.customerIsNewbie) {
            this.setCustomerIsNewbie(false)
          }
          this.job.id = id
          this.job.slug = parseSlug(slug)
        }
      } catch (e: any) {
        const ERR_MSG = 'Job saving error'
        if (ErrorMatcher.isConflict(e)) {
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: e.response.data.message || ERR_MSG,
          })
        } else {
          this.parseError(e)
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: ERR_MSG,
          })
        }
        throw e
      }
    },
    async saveAndPublishJob () {
      this.publishing = true
      try {
        if (this.isUpdated) {
          await this.saveJob()
        }
        try {
          await publishJob(this.job.id)
          this.openSnackbar({
            text: this.job.moderation_stage === ModerationStages.MANUAL
              ? 'Your Job was successfully submitted for moderation. We will notify you of the outcome shortly.'
              : `Job: ${this.job.name} was successfully published`
          })
        } catch (e) {
          this.parseError(e)
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: 'There was an error publishing your Job. Please try again.',
          })
        }
      } finally {
        this.publishing = false
      }
    },
    async signupAndPublish () {
      this.openModal({
        component: 'lx-sign-up-modal',
        props: {
          predefinedRole: Roles.CUSTOMER,
          meta: { job: this.job.toServer() },
          fromType: 'fromJob',
          onSuccess: () => {
            this.readOnly = true
          },
        }
      })
    },
    async updateJob () {
      this.updating = true
      try {
        await this.saveJob()
      } finally {
        this.updating = false
      }
    },
  },
  metaInfo () {
    return {
      title: this.preloading
        ? 'Loading'
        : (this.oldJob?.name ? `Edit "${this.oldJob.name}"` : 'Post freelance job'),
      meta: [
        {
          name: 'description',
          // eslint-disable-next-line max-len
          content: `Post your jobs on LaborX! We help clients and freelancers connect, providing efficient blockchain transactions and robust protections through smart contracts - wherever you're based`,
        },
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex',
        },
      ]
    }
  }
})
