import Vue from 'vue'
import { mapActions } from 'vuex'

export default Vue.extend<any, any, any, any>({
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    openSignUpClick () {
      this.openModal({
        component: 'lx-sign-up-modal',
        props: {
          fromType: 'anotherJob',
        }
      })
    },
  }
})
