import Vue from 'vue'
import debounce from 'lodash/debounce'
import times from 'lodash/times'
import uniq from 'lodash/uniq'
import { mapGetters, mapActions } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import { FREELANCERS_LIST_BY_SKILL, FREELANCERS_LIST } from '@/constants/routes'
import ApplicationCard from './ApplicationCard/ApplicationCard.vue'
import VacancyApplicationItem from '@/models-ts/vacancies/VacancyApplicationItem'
import { VacancyApplicationStatuses } from '@/constants/vacancies/vacancyApplicationStatuses'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  components: {
    ApplicationCard,
  },
  data () {
    return {
      FREELANCERS_LIST_BY_SKILL,
      FREELANCERS_LIST,
      observer: null,
      readedIds: [],
      filter: 'all',
    }
  },
  computed: {
    ...mapGetters({
      vacancy: 'vacancyDetails/vacancy',
    }),
    applications () {
      return this.vacancy.applications.filter((app: VacancyApplicationItem) => app.isActive)
    },
    allApplications () {
      return this.applications.filter((app: VacancyApplicationItem) => !app?.meta?.archived)
    },
    favouritesApplications () {
      return this.applications.filter((app: VacancyApplicationItem) => app?.meta?.favorited)
    },
    messagedApplications () {
      return this.applications.filter((app: VacancyApplicationItem) => app.meta?.messaged)
    },
    archivedApplications () {
      return this.applications.filter((app: VacancyApplicationItem) => app?.meta?.archived
      )
    },
    filteredApplications () {
      switch (this.filter) {
        case 'all': return this.allApplications
        case 'favourites': return this.favouritesApplications
        case 'messaged': return this.messagedApplications
        case 'archive': return this.archivedApplications
      }
    },
    categoryUrl () {
      return this.vacancy.category?.url
    },
    firstSkillUrl () {
      const skills = this.vacancy.relations?.Skill
      return (skills && skills.length > 0) && skills[0].url
    },
    moreTalentsUrl () {
      const skillUrl = this.categoryUrl || this.firstSkillUrl
      return skillUrl
        ? { name: FREELANCERS_LIST_BY_SKILL, params: { skill: skillUrl } }
        : { name: FREELANCERS_LIST }
    }
  },
  watch: {
    'applications.length': {
      handler () {
        if (process.client && this.applications.length) {
          this.$nextTick(() => {
            this.initObserver()
          })
        }
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions({
      readApplications: 'vacancyDetails/readApplications',
    }),
    initObserver () {
      if (this.$refs.card) {
        const options = {
          root: this.$el,
          rootMargin: '0px',
          threshold: times(10, (n) => n / 10 + 0.1)
        }
        if (!this.observer) {
          this.observer = new IntersectionObserver(this.onVisible, options)
        }
        (this.$refs.card || []).forEach((card: any, i: number) => {
          const application: VacancyApplicationItem = this.applications[i]
          if (
            !application.isRead &&
            !this.readedIds.includes(application.id)
          ) {
            this.observer.observe(card.$el || card)
          }
        })
      }
    },
    destroyObserver () {
      if (this.observer) {
        this.observer.disconnect()
        this.observer = null
      }
    },
    onVisible (entries: any) {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0.9) {
          const index = (this.$refs.card || []).findIndex((card: any) => entry.target === (card.$el || card))
          if (this.applications[index] && !this.applications[index].isRead) {
            this.readedIds.push(this.applications[index].id)
            if (this.observer) {
              this.observer.unobserve(entry.target)
            }
          }
        }
      }
      if (this.readedIds.length) {
        this.readDebounced()
      }
    },
    readDebounced: debounce(async function (this: any) {
      const ids = uniq(this.readedIds)
      if (ids.length) {
        this.readedIds = this.readedIds.filter((id: number) => !ids.includes(id))
        try {
          await this.readApplications({ vacancyId: this.vacancy.id, ids })
        } catch (e) {
          console.error('Error reading application', e)
          this.readedIds.push(...ids)
        }
      }
    }, 1000),
  },
})
