import Vue from 'vue'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { MIN_BUDGET, MAX_BUDGET, MIN_BUDGET_ERROR } from '@/utils-ts/rules/jobValidation'
import Job from '@/models-ts/job/Job'

export default Vue.extend({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: [String, Number],
    isEdit: Boolean,
    job: Job,
  },
  data () {
    return {
      MAX_BUDGET,
    }
  },
  computed: {
    budgetError () {
      const err = this.errors?.items.find((err: { field: string }) => err.field === 'budget')
      if (err) {
        if (err.rule === 'min_value') {
          return MIN_BUDGET_ERROR
        } else {
          return err.msg
        }
      }
    },
    budgetValidation () {
      return `numericInputRequired|min_value:${MIN_BUDGET}`
    },
  },
  methods: {
    onInput (v: string) {
      this.$emit('input', v)
      const params = {
        'event': 'budget-add',
        name: v,
      }
      if (this.isEdit) {
        // @ts-ignore
        params.job_id = this.job.id
      }
      googleAnalyticsV2.send(params)
    },
    async validate (publishing: boolean) {
      this.errors?.clear()
      if (publishing || this.value) {
        return this.$validator.validateAll()
      }
      return true
    },
  },
})
