import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import { RootState } from '@/store'
import snackMixin from '@/mixins/snackMixin'
import { AccountTypes } from '@/constants/user/accountTypes'
import AiTalentRecommendation from '@/models-ts/vacancies/AiTalentRecommendation'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import { RoomTypes } from '@/constants/chat/RoomTypes'
import { VACANCY_CHAT } from '@/constants/routes'
import VacancyApplicationItem from '@/models-ts/vacancies/VacancyApplicationItem'
import ChatParticipant from '@/models-ts/chat/ChatParticipant'
import uniqueId from 'lodash/uniqueId'
import { Roles } from '@/constants/user/roles'
import { RoomStages } from '@/constants/chat/RoomStages'
import { VacancyApplicationStatuses } from '@/constants/vacancies/vacancyApplicationStatuses'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, snackMixin],
  components: {
    UserInfo,
  },
  props: {
    recommendation: AiTalentRecommendation,
  },
  data () {
    return {
      AccountTypes,
    }
  },
  computed: {
    ...mapState<RootState>({
      myUserId: (state: any) => state.user?.id,
      myAvatar: (state: any) => state.user?.avatar,
      chatInitialized: (state: RootState) => state.chatNew?.initialized,
      chatConnected: (state: RootState) => state.chatNew?.connected,
    }),
    ...mapGetters({
      myUserName: 'user/name',
      vacancy: 'vacancyDetails/vacancy',
      getUnreadCount: 'chatNew/getUnreadCount',
    }),
    user () {
      return this.application.freelancer
    },
    avatar () {
      return this.recommendation.avatar
    },
    application (): VacancyApplicationItem | undefined {
      return (this.vacancy?.applications || [])
        .find((app: VacancyApplicationItem) =>
          [VacancyApplicationStatuses.NEW, VacancyApplicationStatuses.IN_PROGRESS].includes(app.status) &&
          app.freelancer?.id === this.recommendation.id
        )
    },
    chatRoomId () {
      if (this.application) {
        return ChatRoom.generateRoomId({
          type: RoomTypes.VACANCY,
          applicationId: this.application.id,
          freelancerId: this.application.freelancer.id,
          customerId: this.myUserId,
        })
      }
    },
    chatLink () {
      if (this.chatRoomId) {
        return {
          name: VACANCY_CHAT,
          params: { id: this.vacancy.id, slug: this.vacancy.slug },
          query: { room: this.chatRoomId }
        }
      }
    },
    hasNewMsgs () {
      if (!this.chatRoomId) return false
      const count: number = this.getUnreadCount(this.chatRoomId)
      return Boolean(count)
    },
    chatParticipantFreelancer (): ChatParticipant {
      return new ChatParticipant({
        id: ChatParticipant.toSenderId(this.recommendation.id, Roles.FREELANCER),
        avatar: this.recommendation.avatar,
        name: this.recommendation.name,
        isBanned: false,
        isRemoved: false,
        isOnline: false,
      })
    },
    chatParticipantCustomer (): ChatParticipant {
      return new ChatParticipant({
        id: ChatParticipant.toSenderId(this.myUserId, Roles.CUSTOMER),
        avatar: this.myAvatar,
        name: this.myUserName,
        isBanned: false,
        isRemoved: false,
        isOnline: false,
      })
    },
  },
  methods: {
    ...mapActions({
      openRoom: 'chatNew/openRoom',
      createRoom: 'chatNew/createRoom',
      openModal: 'ui/openModal',
    }),
    async onChatClick () {
      if (this.chatLink) {
        this.openRoom(this.chatRoomId)
        this.$router.push(this.chatLink)
      } else {
        let room = new ChatRoom({
          id: uniqueId('tmp_room_'),
          type: RoomTypes.VACANCY,
          participants: [
            this.chatParticipantCustomer,
            this.chatParticipantFreelancer
          ],
          entity: { id: this.vacancy.id, slug: '-', name: '-' },
          temporary: true,
          customerStage: RoomStages.NEW,
        })
        room = await this.createRoom(room)
        this.$router.push(room.chatLink)
      }
    },
  },
})
