import Vue from 'vue'
import rolebleMixin from '@/mixins/rolebleMixin'
import shareableMixin from '@/mixins/shareableMixin'
import {
  JOB_ADD,
  JOB_EDIT,
} from '@/constants/routes'
import { formatUsd } from '@/utils/moneyFormat'
import { CURRENCIES } from '@/constants/currency'
import { formatDate, getDiff } from '@/utils/date'
import jobActionsMixin from '../jobActionsMixin'
import PreferredCurrencies from '@/partials/PreferredCurrencies/PreferredCurrencies.vue'
import BookmarkButton from '@/partials/BookmarkButtons/BookmarkButton/BookmarkButton.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin, jobActionsMixin, shareableMixin],
  components: {
    BookmarkButton,
    PreferredCurrencies,
  },
  data () {
    return {
      JOB_ADD,
      JOB_EDIT,
    }
  },
  computed: {
    hasDelivery () {
      return !!this.job.delivery_time_at
    },
    daysLeft () {
      const days = Math.max(getDiff(this.job.delivery_time_at, Date.now(), 'day'), 0)
      return days === 1 ? `${days} day` : `${days} days`
    },
    deliveryFormat () {
      return formatDate(this.job.delivery_time_at, 'MMM Do, YYYY')
    },
    budget () {
      return formatUsd(this.job.budget)
    },
    jobCurrencies () {
      return CURRENCIES || []
    },
  },
})
