import Vue, { PropType } from 'vue'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import File from '@/models-ts/File'
import Job from '@/models-ts/job/Job'

const MAX_FILES = 10

export default Vue.extend({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: Array as PropType<UploadedFile[] & File[]>,
    isEdit: Boolean,
    job: Job,
  },
  data () {
    return {
      MAX_FILES,
    }
  },
  methods: {
    onUpload (file: UploadedFile) {
      this.$emit('input', (this.value || []).concat(file))
      const params = {
        event: 'file-upload',
      }
      if (this.isEdit) {
        // @ts-ignore
        params.job_id = this.job.id
      }
      googleAnalyticsV2.send(params)
    },
    onRemove (file: any) {
      const files = this.value || []
      let index = -1
      if (file.base64) {
        index = files.findIndex((fl: any) => fl === file)
      } else {
        index = files.findIndex((fl: any) => fl.name === file.name)
      }
      if (index !== -1) {
        files.splice(index, 1)
      }
    },
  },
})
