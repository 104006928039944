import Vue from 'vue'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { MAX_LENGTH_TITLE } from '@/utils-ts/rules/jobValidation'
import Job from '@/models-ts/job/Job'

export default Vue.extend<any, any, any, any>({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: String,
    isEdit: Boolean,
    job: Job,
  },
  computed: {
    validationRule () {
      return `required|max:${MAX_LENGTH_TITLE}`
    },
  },
  methods: {
    onInput (v: string) {
      this.$emit('input', v)
    },
    onBlur () {
      if (this.value) {
        this.$emit('input', this.value.trim())
      }
    },
    onFocus () {
      const params = {
        event: 'field-start',
        name: 'job-name',
      }
      if (this.isEdit) {
        // @ts-ignore
        params.job_id = this.job.id
      }
      googleAnalyticsV2.send(params)
    },
    onFocusout () {
      const params = {
        event: 'field-finished',
        name: 'job-name',
      }
      if (this.isEdit) {
        // @ts-ignore
        params.job_id = this.job.id
      }
      googleAnalyticsV2.send(params)
    },
    async validate () {
      this.errors.clear()
      return this.$validator.validateAll()
    },
  },
})
