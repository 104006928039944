import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import striptags from 'striptags'
import unescape from 'lodash/unescape'
import Skill from '@/models-ts/Skill'
import { RootState } from '@/store'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { DESC_MIN, DESC_MAX, DESC_MIN_ERROR, DESC_MAX_ERROR } from '@/utils-ts/rules/jobValidation'
import { aiAssistant } from '@/servicies-ts/AIAssistantService'
import Job from '@/models-ts/job/Job'

interface Data {
  DESC_MIN: number
  DESC_MAX: number
  aiLoading: boolean
}

export default Vue.extend({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: String,
    name: String,
    skills: Array as PropType<Skill[]>,
    isEdit: Boolean,
    job: Job,
  },
  data (): Data {
    return {
      DESC_MIN,
      DESC_MAX,
      aiLoading: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
    }),
    length () {
      return this.value ? striptags(this.value).length : 0
    },
    level () {
      if (this.length < 50) {
        return 'Poor'
      }
      if (this.length < 100) {
        return 'Good'
      }
      return 'Excellent'
    },
    levelHint (): string | undefined {
      switch (this.level) {
        case 'Poor': return 'Your description is too short, please write at least 50 characters.'
        case 'Good': return 'To improve your description, try writing up to 100 characters.'
        case 'Excellent': return 'Your description is excellent. Nice work!'
      }
    },
    aiAssistantDisabled () {
      return this.skills?.length === 0 || !this.name
    },
    requiredSkills () {
      return this.skills?.map((skill: Skill) => skill.name)
    },
  },
  beforeDestroy () {
    aiAssistant.destroy()
  },
  methods: {
    async clickToAiBtn () {
      if (this.aiLoading) {
        return
      }
      try {
        this.aiLoading = true
        const description = await aiAssistant.generateDescription({
          name: this.name,
          skills: this.requiredSkills,
          type: 'job',
        })
        this.onInput(description)
        // @ts-ignore
        this.$refs.editor?.setData?.(description)
      } finally {
        this.aiLoading = false
      }
    },
    onInput (v: string) {
      this.$emit('input', v)
    },
    onFocus () {
      const params = {
        event: 'field-start',
        name: 'description',
      }
      if (this.isEdit) {
        // @ts-ignore
        params.job_id = this.job.id
      }
      googleAnalyticsV2.send(params)
    },
    onFocusout () {
      const params = {
        event: 'field-finished',
        name: 'description',
      }
      if (this.isEdit) {
        // @ts-ignore
        params.job_id = this.job.id
      }
      googleAnalyticsV2.send(params)
    },
    validate (publishing: boolean) {
      this.errors?.clear()
      let descriptionLengthIsValid = true
      if (publishing || this.value) {
        const description = unescape(striptags(this.value))
        if (description.length < DESC_MIN) {
          descriptionLengthIsValid = false
          this.errors?.add({
            field: 'value',
            msg: DESC_MIN_ERROR,
          })
        } else if (description.length > DESC_MAX) {
          descriptionLengthIsValid = false
          this.errors?.add({
            field: 'value',
            msg: DESC_MAX_ERROR,
          })
        }
      }
      return descriptionLengthIsValid
    },
  },
})
