import Vue from 'vue'
import { mapGetters } from 'vuex'
import shareableMixin from '@/mixins/shareableMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [shareableMixin],
  props: {
    onSocialItemClick: Function,
  },
  computed: {
    ...mapGetters({
      job: 'jobDetails/job',
    }),
    name () {
      return this.job?.name || ''
    },
    facebookLink () {
      let text = `Check out my Job on LaborX: ${this.name}`
      const hashtag = this.hashtags[0]
      // eslint-disable-next-line
      return `https://www.facebook.com/dialog/share?app_id=${process.env.VUE_APP_FACEBOOK_APP_ID}&href=${this.shareUrl}&display=popup&quote=${text}&hashtag=%23${hashtag}&redirect_uri=${this.shareUrl}`
    },
    twitterLink () {
      const text = `Check out my Job on LaborX: ${encodeURIComponent(this.name)} %0A%0AApply now ⬇️%0A${this.shareUrl} %0A%0A`
      const hashtags = this.hashtags.join(',')
      return `https://twitter.com/intent/tweet?text=${text}&hashtags=${hashtags}`
    },
    telegramLink () {
      let text = `Check out my Job on LaborX: ${this.name}`
      return `https://telegram.me/share/url?url=${this.shareUrl}&text=${text}`
    },
    linkedinLink () {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${this.shareUrl}`
    },
    links () {
      return {
        facebook: this.facebookLink,
        twitter: this.twitterLink,
        telegram: this.telegramLink,
        linkedin: this.linkedinLink,
      }
    },
  },
})
