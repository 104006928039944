import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import { getHumanDate } from '@/utils/date'
import JobApplication from '@/models-ts/job/JobApplication'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import { formatUsd } from '@/utils/moneyFormat'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import { JOB_CHAT } from '@/constants/routes'
import { RoomTypes } from '@/constants/chat/RoomTypes'
import { JobOfferStages } from '@/constants/job/jobOfferStages'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { STATUS_PUBLISHED } from '@/constants/job/jobStatuses'
import { JobApplicationStatuses } from '@/constants/job/jobApplicationStatuses'
import { ModerationStages } from '@/constants/backend/ModerationStages'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  components: {
    UserInfo,
  },
  props: {
    application: JobApplication,
  },
  data () {
    return {
      declining: false,
      favouriting: false,
      archiving: false,
    }
  },
  computed: {
    ...mapState({
      createContractLoading: (state: any) => state.jobDetails.createContractLoading,
    }),
    ...mapGetters({
      job: 'jobDetails/job',
      getUnreadCount: 'chatNew/getUnreadCount',
    }),
    jobIsPublished () {
      return this.job.status === STATUS_PUBLISHED && this.job.moderation_stage === ModerationStages.PASSED
    },
    isSelectedApplication () {
      return this.application.offer?.stage === JobOfferStages.ACCEPTED_BY_CUSTOMER
    },
    user () {
      return this.application.freelancer
    },
    depositing () {
      return Boolean(this.createContractLoading[this.job?.sc_id])
    },
    budget () {
      let budget = this.application.budget
      if (this.application.offer) {
        budget = this.application.offer.budget
      }
      if (budget) {
        return formatUsd(budget)
      }
    },
    deadline () {
      let deadline: number = this.application.deadlineInDays
      if (this.application.offer) {
        deadline = this.application.offer.deadlineInDays
      }
      if (deadline) {
        return deadline === 1 ? '1 day' : `${deadline} days`
      }
    },
    hasDecline () {
      return !this.job.wasStarted
    },
    hasHire () {
      return this.application.offer?.stage === JobOfferStages.NEW && !this.job.wasStarted
    },
    hasDeposit () {
      return this.application.offer?.stage === JobOfferStages.ACCEPTED_BY_CUSTOMER &&
        !this.job.wasStarted &&
        this.job.status === STATUS_PUBLISHED &&
        this.job.moderation_stage === ModerationStages.PASSED
    },
    chatRoomId () {
      return ChatRoom.generateRoomId({
        type: RoomTypes.JOB,
        applicationId: this.application.id,
        freelancerId: this.application.freelancer.id,
        customerId: this.application.customer.id,
      })
    },
    chatLink () {
      return {
        name: JOB_CHAT,
        params: { id: this.application.job.id, slug: this.application.job.slug },
        query: { room: this.chatRoomId }
      }
    },
    hasNewMsgs () {
      const count: number = this.getUnreadCount(this.chatRoomId)
      return Boolean(count)
    },
    hasActions () {
      return this.jobIsPublished && this.application.status !== JobApplicationStatuses.ARCHIVED
    },
    isArchived () {
      return this.application.meta?.archived
    },
    isFavourite () {
      return this.application.meta?.favourite
    },
    hasFavouriteBtn () {
      return this.isFavourite || !this.isArchived
    },
    hasArchiveBtn () {
      return !this.isSelectedApplication || this.isArchived
    },
  },
  methods: {
    getHumanDate,
    ...mapActions({
      openModal: 'ui/openModal',
      openRoom: 'chatNew/openRoom',
      setApplicationMeta: 'jobDetails/setApplicationMeta',
    }),
    onChatClick () {
      googleAnalyticsV2.send({
        event: 'response-to-job',
        'event-content': 'customer-button-click-chat',
        job_id: this.job.id,
      })
      this.openRoom(this.chatRoomId)
    },
    onDepositClick () {
      this.$emit('deposit', this.application)
    },
    async onFavouriteClick () {
      this.favouriting = true
      try {
        await this.setApplicationMeta({
          jobId: this.job.id,
          applicationId: this.application.id,
          meta: { ...this.application.meta, favourite: !this.isFavourite },
        })
      } finally {
        this.favouriting = false
      }
    },
    async onArchiveClick () {
      this.archiving = true
      try {
        await this.setApplicationMeta({
          jobId: this.job.id,
          applicationId: this.application.id,
          meta: { ...this.application.meta, archived: !this.isArchived },
        })
      } finally {
        this.archiving = false
      }
    },
  },
})
