import Vue from 'vue'
// @ts-ignore
import NoSsr from 'vue-no-ssr'
import { mapActions, mapGetters, mapState } from 'vuex'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import rolebleMixin from '@/mixins/rolebleMixin'
import zendeskMixin from '@/mixins/zendeskMixin'
import snackMixin from '@/mixins/snackMixin'
import { removeJob } from '@/api/jobs/job'
import {
  JOB_ADD,
  JOB_EDIT,
  JOBS_POSTED,
  MY_JOBS
} from '@/constants/routes'
import { STATUS_PUBLISHED } from '@/constants/job/jobStatuses'
import { STAGE_NEW, STAGE_STARTED } from '@/constants/job/jobStages'
import { Roles } from '@/constants/user/roles'
import JobApplication from '@/models-ts/job/JobApplication'
import { JobApplicationStatuses } from '@/constants/job/jobApplicationStatuses'
import Button from '@/models-ts/ui/Button'
import { declineApplicationAsFreelancer, sendApplication } from '@/api/jobs/applications'
import { BookmarkTypes } from '@/constants/bookmarks/bookmarkType'
import { JobApplicationFormData } from '@/modals/SendJobApplication/SendJobApplication'
import { getDiff } from '@/utils/date'
import ErrorMatcher from '@/utils/ErrorMatcher'
import { validateJobPublish, canPublish } from '@/utils-ts/rules/jobValidation'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin, zendeskMixin, snackMixin],
  components: { NoSsr },
  data () {
    return {
      publishing: false,
      published: false,
      deleting: false,
      JOB_ADD,
      JOB_EDIT,
      bookmarkType: BookmarkTypes.JOB,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      hasSkills: (state: any) => state.user?.hasSkills,
    }),
    ...mapGetters({
      job: 'jobDetails/job',
      isCustomerParticipant: 'jobDetails/isCustomer',
      isFreelancerParticipant: 'jobDetails/isFreelancer',
    }),
    failedModeration () {
      return this.job.moderation_stage === ModerationStages.FAILED
    },
    hasPost () {
      return !this.isLoggedIn || this.isFreelancer
    },
    hasPublishBtn () {
      return this.isCustomer &&
        this.isCustomerParticipant &&
        canPublish(this.job)
    },
    hasEditBtn () {
      return this.job.isNotStarted
    },
    hasDeleteBtn () {
      return this.isCustomer && this.isCustomerParticipant && [STAGE_NEW, STAGE_STARTED].includes(this.job.stage)
    },
    application () {
      return this.job.applications.find((app: JobApplication) => app.status === JobApplicationStatuses.NEW)
    },
    hasApplyBtn () {
      return this.job.isNotStarted && (!this.isLoggedIn || (this.isFreelancer &&
        !this.isCustomerParticipant &&
        (!this.application || this.application.status === JobApplicationStatuses.ARCHIVED))
      )
    },
    hasCancelAppBtn () {
      return this.isFreelancer &&
      !this.isCustomerParticipant &&
      this.job.isNotStarted &&
      this.application?.status === JobApplicationStatuses.NEW
    },
    hasBookmarkBtn () {
      return !this.isCustomerParticipant
    },
    hasMenu () {
      return this.hasPublishBtn || this.hasDeleteBtn || this.hasBookmarkBtn
    },
    hasShareBtn () {
      return this.job.moderation_stage === ModerationStages.PASSED && this.job.status === STATUS_PUBLISHED
    },
  },
  created () {
    this.published = this.job.status === STATUS_PUBLISHED
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      publish: 'jobDetails/publish',
      unpublish: 'jobDetails/unpublish',
    }),
    async onPublishClick () {
      if (!this.job.wasStarted) {
        if (this.job.isPublished) {
          this.openModal({
            component: 'lx-composite-modal-new',
            props: {
              title: 'Job unpublish',
              text: `<div style="max-width:536px">
              After you unpublish this Job, all applications from talents will be declined automatically.
              </div>`,
              buttons: [
                new Button({
                  text: 'Cancel',
                  classes: 'lx-transparent-blue-btn',
                }),
                new Button({
                  text: 'Unpublish',
                  classes: 'lx-blue-btn',
                  onClick: async () => {
                    try {
                      this.publishing = true
                      await notifiableRequest({
                        request: () => this.unpublish(this.job.id),
                        title: 'Unpublish job',
                        successText: `Job: <b>${this.job.name}</b> has been unpublished.`,
                        failureText: 'Error unpublishing Job. Please try again.'
                      })
                      googleAnalyticsV2.send({
                        event: 'job-drafted',
                        job_id: this.job.id,
                      })
                    } finally {
                      this.publishing = false
                    }
                  },
                }),
              ]
            }
          })
        } else {
          this.publishing = true
          try {
            const validationError = validateJobPublish(this.job)
            if (!validationError) {
              await notifiableRequest({
                request: () => this.publish(this.job.id),
                title: 'Publish job',
                successText: this.job.moderation_stage === ModerationStages.MANUAL
                  ? 'Your Job was successfully submitted for moderation. We will notify you of the outcome shortly.'
                  : `Job: <b>${this.job.name}</b> was successfully published`,
                failureText: 'Error publishing Job. Please try again.'
              })
              googleAnalyticsV2.send({
                event: 'job-published',
                job_id: this.job.id,
              })
            } else {
              this.openSnackbar({
                type: this.SnackTypes.FAILURE,
                text: validationError,
              })
            }
          } finally {
            this.publishing = false
          }
        }
      }
    },
    async onDeleteClick () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Remove Job',
          text: `Are you sure you want to remove Job: <b>${this.job.name}</b>`,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
            }),
            new Button({
              text: 'Proceed',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  this.deleting = true
                  await notifiableRequest({
                    request: () => removeJob(this.job.id),
                    title: 'Remove Job',
                    successText: `Job <b>${this.job.name}</b> has been deleted.`,
                    failureText: 'Error deleting Job. Please try again.'
                  })
                  this.$router.push({ name: JOBS_POSTED })
                } finally {
                  this.deleting = false
                }
              },
            }),
          ]
        }
      })
    },
    onApplyClick () {
      if (this.isLoggedIn && !this.hasSkills) {
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ProfileFilling/ProfileFilling.vue'),
            title: 'Add Your Skills',
          }
        })
        return
      }
      const deadline = this.job.delivery_time_at
        ? Math.max(getDiff(this.job.delivery_time_at, new Date()), 1)
        : 1
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/SendJobApplication/SendJobApplication.vue'),
          title: 'Apply For This Job',
          props: {
            budget: this.job.budget,
            deadline,
            onSuccess: (application: JobApplicationFormData) => this.isLoggedIn
              ? this.sendApplication(application)
              : this.openModal({
                component: 'lx-sign-up-modal',
                props: {
                  predefinedRole: Roles.FREELANCER,
                  fromType: 'anotherJob',
                  meta: {
                    job_application: {
                      jobId: this.job.id,
                      ...application,
                    }
                  }
                }
              })
          }
        }
      })
      googleAnalyticsV2.send({
        'event': 'job-button-apply-click',
        job_id: this.job.id,
      })
    },
    onCancelClick () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Cancel application?',
          text: `This application will be removed from Customer's applications list.`,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
            }),
            new Button({
              text: 'Proceed',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  await declineApplicationAsFreelancer(this.application.id)
                  this.$router.push({ name: MY_JOBS })
                } catch (e) {
                  this.parseError(e)
                }
              },
            }),
          ]
        }
      })
    },
    onClickPost () {
      googleAnalyticsV2.send({
        event: 'click-on-button-post-job-like-this',
        job_id: this.job.id,
      })
      this.$emit('postJob')
    },
    onOpenProfile () {
      googleAnalyticsV2.send({
        event: 'gig-click-on-user',
        'event-content': 'gig-page-summary-block',
        job_id: this.job.id,
      })
    },
    async sendApplication (application: JobApplicationFormData) {
      await notifiableRequest({
        request: async () => sendApplication({
          jobId: this.job.id,
          ...application,
        }),
        title: 'Apply for this Job',
        successText: 'Your application has been submitted.',
        failureText: (err) => ErrorMatcher.isConflict(err)
          ? 'You have already applied for this Job.'
          : 'Error submitting application. Please try again.',
      })
      googleAnalyticsV2.send({
        event: 'response-to-job',
        'event-content': 'freelancer-response-step-1',
        job_id: this.job.id,
      })
      this.$router.push({ name: MY_JOBS })
    },
    onClickSupport () {
      if (!this.isZendeskLoading) {
        this.openZendesk('My account')
      }
    },
    onClickShare () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "share-modal" */ '@/modals/ShareModal/ShareModal.vue'),
          title: 'Share this Job',
          props: {
            type: 'job',
            id: this.job.id,
            slug: this.job.slug,
            name: this.job.name,
          },
        }
      })
    },
  },
})
