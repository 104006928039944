/* eslint-disable max-len */
export default [
  {
    question: 'What careers are there in crypto?',
    answer: 'The crypto industry offers many different kinds of jobs, including both technical and non-technical roles. Some, like blockchain engineer and developer jobs, will require an in-depth knowledge of blockchain. Others, such as marketing and communications roles, will need some higher-level understanding. Many support roles, including customer service and administration, will require little to no specific expertise – though learning as much as you can will always be helpful.',
  },
  {
    question: 'How much do crypto workers make?',
    answer: 'Some of the highest-paying jobs in the blockchain space will pay well into six figures, with the top engineering roles earning salaries of $250,000 or more, plus token and/or stock options. Of course, entry-level jobs are not this well paid. However, there is a shortage of qualified talent for crypto jobs across the board. As a result, you can expect compensation to be significantly higher than for comparable roles in the wider jobs market. Pay is typically around 15-20% higher than for similar positions.',
  },
  {
    question: 'How do I get a job in crypto?',
    answer: 'If you’re interested in getting a job in the blockchain space, the best thing is learn as much as you can, and get involved in whatever way possible. You’ll find lots of grassroots communities who will help you understand the tech, and apply any skills you have – whether as a developer, in communications, marketing, or other roles. This may lead directly to a job in its own right, but it will also help you to stand out if you apply for jobs you have found via more conventional means, like searching LinkedIn and other major sites for suitable vacancies.',
  },
  {
    question: 'Do crypto jobs pay well?',
    answer: 'Jobs in the crypto and Web3 world have a reputation for paying more than similar roles in other sectors. This is down to the specialist nature of the technology and the fact that there are still relatively few people who understand it well and have the experience necessary to fill the positions that exist. At the very top end, pay can be astronomically high, but even in entry-level jobs you’ll enjoy a ‘crypto premium’ of 15-20% above comparable jobs in other companies.',
  },
]
