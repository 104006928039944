import Vue from 'vue'
// @ts-ignore
import NoSsr from 'vue-no-ssr'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import responseMixin from '@/mixins/responseMixin'
import shareableMixin from '@/mixins/shareableMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import {
  JOB_DETAILS,
  BROWSE_JOBS_BY_SKILL,
  MY_JOBS,
  JOB_ADD,
  JOB_DETAILS_ADMIN,
  JOB_DETAILS_ADMIN_DESCRIPTION,
  JOB_DETAILS_ADMIN_APPLICATIONS
} from '@/constants/routes'
import { stripDescriptionTags } from '@/utils-ts/strings'
import { formatUsd } from '@/utils/moneyFormat'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import JobApplication from '@/models-ts/job/JobApplication'
import GigCard from '@/partials/GigCard/GigCard.vue'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import rolebleMixin from '@/mixins/rolebleMixin'
import snackMixin from '@/mixins/snackMixin'
import { addMonths, formatDate, getHumanDate } from '@/utils/date'
import { META_COUNTRIES } from '@/constants/meta'
import { DATE_TIME_FORMAT_WITHOUT_SEC } from '@/constants/utils/date'
import { JobApplicationStatuses } from '@/constants/job/jobApplicationStatuses'
import { RootState } from '@/store'
import AdminActionsCard from '../components/AdminActionsCard/AdminActionsCard.vue'
import ApplicationsList from './ApplicationsList/ApplicationsList.vue'
import JobDescription from '../components/JobDescription/JobDescription.vue'
import MobileActionsCard from '../components/MobileActionsCard/MobileActionsCard.vue'
import ShareBlock from '../components/ShareBlock/ShareBlock.vue'
import HowItWorksCard from '../components/HowItWorksCard/HowItWorksCard.vue'
import SignUpCard from '../components/SignUpCard/SignUpCard.vue'
import JobStatusBadge from '@/partials/StatusBadges/JobStatusBadge/JobStatusBadge.vue'
import Job from '@/models-ts/job/Job'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, shareableMixin, snackMixin, canonicalMixin, rolebleMixin],
  components: {
    AdminActionsCard,
    ApplicationsList,
    MobileActionsCard,
    JobDescription,
    HowItWorksCard,
    NoSsr,
    ShareBlock,
    SignUpCard,
    UserInfo,
    GigCard,
    JobStatusBadge,
  },
  data () {
    return {
      BROWSE_JOBS_BY_SKILL,
      JOB_DETAILS_ADMIN_DESCRIPTION,
      JOB_DETAILS_ADMIN_APPLICATIONS,
      MY_JOBS,
      starting: false,
      mobileMoreSkillsOpened: false,
      isDescriptionActive: true,
      shouldClearJob: true,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      prefetched: (state: RootState) => state.jobDetails.prefetched,
      profiles: (state: RootState) => state.user?.profiles,
      userIsLoaded: (state: RootState) => state.user?.profile.isLoaded,
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      job: 'jobDetails/job',
      jobId: 'jobDetails/jobId',
      isLoading: 'jobDetails/isLoading',
      isLoaded: 'jobDetails/isLoaded',
      isOwner: 'jobDetails/isCustomer',
      isMember: 'jobDetails/isMember',
    }),
    pageIsLoaded () {
      return this.isLoaded && this.userIsLoaded
    },
    inArchive () {
      return this.job && !this.job.isNotStarted && !this.isMember
    },
    hasSignUpCard () {
      return this.inArchive && !this.isLoggedIn
    },
    skills () {
      const skills = this.job.relations.Skill
        .filter((skill: any) => skill.id !== this.category.id)
        .map((skill: any) => skill.name)
      return skills
    },
    categoryGigs () {
      return this.job.more.gigsByCategory
    },
    userJobs () {
      return this.job.more.byUser
    },
    categoryJobs () {
      return this.job.more.byCategory
    },
    name () {
      return this.job?.name
    },
    category () {
      return this.job.meta.category || {}
    },
    budget () {
      if (!this.job) {
        return ''
      }
      return this.isMember && this.job.wasStarted ? this.job.formattedBudget : `$${formatUsd(this.job.budget)}`
    },
    customer () {
      return this.job?.relations.Customer
    },
    hasReview () {
      return (this.job as Job).relations.Review?.some(r => r.fromClient)
    },
    unreadCount () {
      const applications = (this.job.applications || []).filter((app: JobApplication) => app.status === JobApplicationStatuses.NEW)
      const count = applications.reduce((count: number, app: JobApplication) => !app.isRead ? count + 1 : count, 0)
      return count > 99 ? '99+' : count
    },
    title () {
      if (!this.name) {
        return 'Loading'
      }
      return `${this.name} - freelance job${this.category ? ` in ${this.category.name}` : ''}, ${this.budget} price in cryptocurrency`
    },
    metaDescription () {
      if (!this.name) {
        return ''
      }
      // eslint-disable-next-line max-len
      return `Apply for ${this.name} job${this.category ? ` in ${this.category.name}` : ''}, Fixed price ${this.budget} in cryptocurrency. It takes just a few clicks!`
    },
    hasProposedGigs () {
      return this.categoryGigs.length
    },
  },
  watch: {
    '$route': {
      handler () {
        this.isDescriptionActive = this.$route.name === JOB_DETAILS_ADMIN_DESCRIPTION || this.$route.name === JOB_DETAILS_ADMIN
        if (!this.isDescriptionActive) {
          googleAnalyticsV2.send({
            event: 'job-apps-view',
            job_id: this.$route.params.id,
          })
          googleAnalyticsV2.send({
            event: 'freelkjob-applications-view',
            job_id: this.$route.params.id,
          })
        }
      },
      immediate: true,
    },
    '$route.params.id': {
      handler (newJob, oldJob) {
        if (newJob && String(newJob) !== String(oldJob)) {
          this.clearJob()
          this.load({ id: newJob, slug: this.$route.params.slug })
        }
      }
    },
    isDescriptionActive () {
      if (this.isDescriptionActive) {
        this.initSwiper()
      }
    },
    isLoaded: {
      handler () {
        if (this.isLoaded) {
          this.$nextTick(async () => {
            this.sendAnalytics()
            this.initSwiper()
          })
        }
      },
      immediate: true,
    },
    pageIsLoaded () {
      if (this.pageIsLoaded) {
        if (!this.isOwner || this.isFreelancer) {
          this.$router.replace({ name: JOB_DETAILS, params: { id: this.job.id, slug: this.job.slug } })
        }
      }
    },
  },
  async created () {
    try {
      if (String(this.job?.id) !== String(this.jobId)) {
        await this.load({ id: this.$route.params.id, slug: this.$route.params.slug })
      }
    } catch (e) {
      if (ErrorMatcher.isGone(e) || ErrorMatcher.isNotFound(e) || ErrorMatcher.isForbidden(e) || ErrorMatcher.isConflict(e)) {
        this.setNotFound(true)
      } else {
        this.parseError(e)
      }
    }
  },
  mounted () {
    googleAnalyticsV2.send({
      event: 'freel-job-view',
      job_id: this.$route.params.id,
    })
  },
  beforeDestroy () {
    if (!this.jobId && this.shouldClearJob) {
      this.clearJob()
    }
  },
  methods: {
    getHumanDate,
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setRedirect: 'app/setRedirect',
      setPrefetched: 'jobDetails/setPrefetched',
      clearJob: 'jobDetails/clearJob',
    }),
    ...mapActions({
      load: 'jobDetails/load',
      signCreateContractV2: 'jobDetails/signCreateContractV2',
    }),
    async initSwiper () {
      // @ts-ignore
      const Swiper = (await import(/* webpackChunkName: "swiper" */ 'swiper')).default
      if (this.$refs.categoryGigs) {
        const swiper = new Swiper(this.$refs.categoryGigs, {
          slidesPerView: 'auto',
          spaceBetween: 32,
          freeMode: true,
          navigation: {
            nextEl: this.$refs[`categoryGigsNextBtn`].$el,
            prevEl: this.$refs[`categoryGigsPrevBtn`].$el,
          },
        })
        swiper.allowTouchMove = false
      }
    },
    async onHired (application: JobApplication) {
      if (application.job.id === this.job.id) {
        await this.load({ id: this.job.id, slug: this.$route.params.slug })
        this.startJob(application)
      }
    },
    onDeposit (application: JobApplication) {
      this.startJob(application)
    },
    async startJob (application: JobApplication) {
      try {
        this.starting = true
        if (this.job) {
          const offer = application.offer
          if (offer) {
            this.signCreateContractV2({
              params: {
                deadline: offer.deadline,
                rate: offer.budget,
                // @ts-ignore
                freelancerWallets: this.job?.meta?.FreelancerWallets || [],
                id: this.job.id,
                slug: this.job.slug,
                scId: this.job.sc_id,
                name: this.job.name,
                offerId: offer.id,
                freelancer: {
                  ...this.job.freelancer,
                  wallet: offer.freelancerWallet,
                },
              },
              successSign: () => {
                this.openSnackbar({
                  type: this.SnackTypes.SUCCESS,
                  text: 'Your funds have successfully been deposited to escrow.',
                })
                this.starting = false
                this.load({ id: this.job.id, slug: this.$route.params.slug })
              },
              cancelSign: () => {
                this.starting = false
              },
            })
          }
        }
      } catch (err) {
        console.error(err)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error starting job'
        })
        this.starting = false
      }
    },
    sendAnalytics () {
      googleAnalyticsV2.send({
        event: 'detailView',
        job_id: this.job.id,
        ecommerce: {
          detail: {
            actionField: {
              list: this.$route.query.fromUrl || '',
            },
            products: [{
              name: this.name,
              id: `${this.job.id}`,
              price: this.budget,
              category: this.$route.query.skill || this.category?.name || '',
              brand: 'Jobs',
            }]
          }
        }
      })
    },
    onClickPostJob () {
      this.shouldClearJob = false
      this.$router.push({ name: JOB_ADD, query: { fromType: 'job', jobId: this.job.id } })
    },
  },
  metaInfo () {
    const meta: Array<any> = [{
      vmid: 'description',
      name: 'description',
      content: this.metaDescription,
    }]
    const script = []
    if (this.job) {
      const getBudgetUnit = (budget: number) => {
        if (budget < 50) {
          return 'HOUR'
        } else if (budget < 200) {
          return 'DAY'
        } else if (budget < 1000) {
          return 'WEEK'
        }
        return 'MONTH'
      }
      meta.push({
        vmid: 'og:title',
        property: 'og:title',
        content: 'New job on LaborX.com',
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: `Job Title: ${this.name}`,
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: this.shareUrl,
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: `${process.env.VUE_APP_FRONTEND_URL}/static/images/laborx-thumbnail.jpg`
      },
      {
        vmid: 'og:type',
        property: 'og:type',
        content: 'website',
      })
      if (!this.inArchive) {
        script.push({
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'JobPosting',
            title: this.name,
            description: stripDescriptionTags(this.job.description, { stripLinks: true }),
            datePosted: formatDate(this.job.created_at, 'YYYY-MM-DD'),
            employmentType: 'CONTRACTOR',
            jobLocationType: 'TELECOMMUTE',
            applicantLocationRequirements: META_COUNTRIES.map(name => ({
              '@type': 'Country', name
            })),
            hiringOrganization: {
              '@type': 'Organization',
              name: this.customer.name,
              ...(this.customer.avatar?.src && { logo: this.customer.avatar.src }),
              ...(this.customer.website && { sameAs: this.customer.website }),
            },
            occupationalCategory: this.category.name,
            ...(this.skills.length && { skills: this.skills.join(', ') }),
            estimatedSalary: {
              '@type': 'MonetaryAmount',
              currency: 'USD',
              value: {
                '@type': 'QuantitativeValue',
                value: this.job.budget,
                unitText: getBudgetUnit(Number(this.job.budget)),
              },
            },
            validThrough: formatDate(addMonths(this.job.published_at, 6), DATE_TIME_FORMAT_WITHOUT_SEC),
          }
        })
      }
    }
    return {
      title: this.title,
      meta,
      script,
      link: [this.canonicalLink],
    }
  }
})
