import Vue from 'vue'
import { getDateFromString } from '@/utils/date'

export default Vue.extend({
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: [String, Date],
  },
  computed: {
    dateString () {
      if (this.value) {
        return getDateFromString(this.value)
      }
      return ''
    }
  },
  methods: {
    onInput (v: string) {
      this.$emit('input', v)
    },
  },
})
