import BookmarkMenuItem from '@/partials/BookmarkButtons/BookmarkMenuItem/BookmarkMenuItem.vue'
import Vue from 'vue'
import jobActionsMixin from '../jobActionsMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [jobActionsMixin],
  components: {
    BookmarkMenuItem,
  },
})
