import { render, staticRenderFns } from "./VacancyDetailsAdmin.htm?vue&type=template&id=01630155&scoped=true&"
import script from "./VacancyDetailsAdmin.ts?vue&type=script&lang=js&"
export * from "./VacancyDetailsAdmin.ts?vue&type=script&lang=js&"
import style0 from "./VacancyDetailsAdmin.scss?vue&type=style&index=0&id=01630155&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01630155",
  null
  
)

export default component.exports