import Vue from 'vue'
import VacancyLocationTooltip from '@/partials/VacancyLocationTooltip/VacancyLocationTooltip.vue'
import actionsMixin from '../actionsMixin'
import { mapActions } from 'vuex'
import snackMixin from '@/mixins/snackMixin'
import notifiableRequest from '@/utils-ts/notifiableRequest'

export default Vue.extend<any, any, any, any>({
  components: {
    VacancyLocationTooltip,
  },
  mixins: [actionsMixin, snackMixin],
  data: () => {
    return {
      unArchiving: false,
    }
  },
  computed: {
    locations () {
      return this.vacancy?.relations?.Location || []
    },
  },
  methods: {
    ...mapActions({
      unArchiveVacancy: 'vacancyDetails/unArchiveVacancy',
    }),
    async onUnArchiveClick () {
      this.unArchiving = true
      await notifiableRequest({
        request: async () => {
          try {
            await this.unArchiveVacancy(this.vacancy.id)
          } catch (err) {
            throw err
          } finally {
            this.unArchiving = false
          }
        },
        title: 'Offer accepted',
        successText: `Job <b>${this.vacancy.name}</b> has been unarchived.`,
        loadingText: `Job <b>${this.vacancy.name}</b> is being unarchived.`,
        failureText: 'Error unarchiving Job. Please try again.',
      })
    },
  },
})
