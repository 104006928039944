import Vue from 'vue'
import Skill from '@/models-ts/Skill'
import { BROWSE_JOBS_BY_SKILL, JOB_DETAILS } from '@/constants/routes'
import { formatUsdWithCutCents } from '@/utils/moneyFormat'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import { formatDate, getHumanDate } from '@/utils/date'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { mapState } from 'vuex'
import { BookmarkTypes } from '@/constants/bookmarks/bookmarkType'
import BookmarkIconButton from '@/partials/BookmarkButtons/BookmarkIconButton/BookmarkIconButton.vue'
import JobListItem from '@/models-ts/job/JobListItem'
import { formatCardsDescription } from '@/utils-ts/strings'

export default Vue.extend<any, any, any, any>({
  components: {
    UserInfo,
    BookmarkIconButton,
  },
  props: {
    job: JobListItem,
  },
  data () {
    return {
      bookmarkType: BookmarkTypes.JOB,
    }
  },
  computed: {
    ...mapState({
      ownUserId: (state: any) => state.user?.id,
    }),
    user () {
      return this.job.user
    },
    isOwnJob () {
      return this.ownUserId === this.user.id
    },
    publishDate () {
      return getHumanDate(this.job.editedAt)
    },
    formattedPublishDate () {
      return formatDate(this.job.editedAt, '')
    },
    category () {
      if (this.job.skills.length) {
        const category = this.job.skills.find((skill: Skill) => !((skill.relations?.Parent || []).length)) || this.job.skills[0]
        return {
          id: category.id,
          text: category.name,
          link: { name: BROWSE_JOBS_BY_SKILL, params: { skill: category.url } }
        }
      }
    },
    skillTags () {
      const skills = (this.job.skills as Array<Skill>)
        .filter(skill => skill.id !== this.category?.id)
        .slice(0, 2)
        .map(skill => ({
          text: skill.name,
          link: this.preview ? null : { name: BROWSE_JOBS_BY_SKILL, params: { skill: skill.url } }
        }))
      return [this.category, ...skills].filter(Boolean)
    },
    budget () {
      return formatUsdWithCutCents(this.job.budget, '99.99')
    },
    jobLink () {
      return { name: JOB_DETAILS, params: { id: this.job.id, slug: this.job.slug } }
    },
    description () {
      return formatCardsDescription(this.job.description)
    }
  },
  methods: {
    onClickJobDetails () {
      googleAnalyticsV2.send({
        event: 'productClick',
        job_id: this.job.id,
        ecommerce: {
          currencyCode: 'USD',
          click: {
            products: [{
              name: this.job.name,
              id: `${this.job.id}`,
              price: this.budget,
              category: this.category?.text || '',
              brand: 'Jobs',
              list: this.$route.fullPath,
              position: 1,
            }]
          }
        },
      })
    },
  }
})
