import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import { getHumanDate } from '@/utils/date'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import Button from '@/models-ts/ui/Button'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import { VACANCY_CHAT } from '@/constants/routes'
import { RoomTypes } from '@/constants/chat/RoomTypes'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import VacancyApplicationItem from '@/models-ts/vacancies/VacancyApplicationItem'
import { Stages } from '@/constants/vacancies/statuses'
import VacancyApplicationMeta from '@/models-ts/vacancies/VacancyApplicationMeta'
import cloneDeep from 'lodash/cloneDeep'
import { RootState } from '@/store'
import snackMixin from '@/mixins/snackMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, snackMixin],
  components: {
    UserInfo,
  },
  props: {
    application: VacancyApplicationItem,
  },
  data () {
    return {
      declining: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      chatInitialized: (state: RootState) => state.chatNew?.initialized,
      chatConnected: (state: RootState) => state.chatNew?.connected,
      favoriteLoading: (state: RootState) => state.vacancyDetails?.appsFavoriteLoading,
      archiveLoading: (state: RootState) => state.vacancyDetails?.appsArchiveLoading,
    }),
    ...mapGetters({
      vacancy: 'vacancyDetails/vacancy',
      getUnreadCount: 'chatNew/getUnreadCount',
    }),
    user () {
      return this.application.freelancer
    },
    hasChat () {
      return this.chatInitialized
    },
    chatRoomId () {
      return ChatRoom.generateRoomId({
        type: RoomTypes.VACANCY,
        applicationId: this.application.id,
        freelancerId: this.application.freelancer.id,
        customerId: this.vacancy.relations.Customer.id,
      })
    },
    chatLink () {
      return {
        name: VACANCY_CHAT,
        params: { id: this.vacancy.id, slug: this.vacancy.slug },
        query: { room: this.chatRoomId }
      }
    },
    hasNewMsgs () {
      const count: number = this.getUnreadCount(this.chatRoomId)
      return Boolean(count)
    },
    isArchived () {
      return this.application?.meta?.archived
    },
    isFavorited () {
      return this.application?.meta?.favorited
    },
  },
  methods: {
    getHumanDate,
    ...mapActions({
      openModal: 'ui/openModal',
      openRoom: 'chatNew/openRoom',
      setApplicationFavoritesMeta: 'vacancyDetails/setApplicationFavoritesMeta',
      setApplicationArchivedMeta: 'vacancyDetails/setApplicationArchivedMeta',
    }),
    onChatClick () {
      googleAnalyticsV2.send({
        event: 'response-to-ftj-job',
        'event-content': 'customer-button-click-chat',
        ftj_id: this.vacancy.id,
      })
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatLink)
    },
    async onFavoriteClick () {
      const meta = cloneDeep(this.application.meta || {})
      if (meta) {
        meta['favorited'] = !meta['favorited']
      } else {
        meta['favorited'] = true
      }
      try {
        await this.setApplicationFavoritesMeta({
          vacancyId: this.vacancy.id,
          applicationId: this.application.id,
          meta
        })
      } catch (err) {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: `Error in during adding/removing favorites applications`,
        })
      }
    },
    async onArchiveClick () {
      const meta = cloneDeep(this.application.meta || {})
      if (meta) {
        meta['archived'] = !meta['archived']
      } else {
        meta['archived'] = true
      }
      try {
        await this.setApplicationArchivedMeta({
          vacancyId: this.vacancy.id,
          applicationId: this.application.id,
          meta
        })
      } catch (err) {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: `Error in during adding/removing archive applications`,
        })
      }
    },
  },
})
