import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import JobCard from '@/partials/JobCards/JobCard/JobCard.vue'
import JobListItem from '@/models-ts/job/JobListItem'
import { formatUsd } from '@/utils/moneyFormat'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'

export default Vue.extend<any, any, any, any>({
  components: { JobCard },
  props: {
    jobs: Array as PropType<Array<JobListItem>>,
    skill: String,
    skillDetails: Object,
  },
  data () {
    return {
      observer: null,
      sendedJobs: [],
    }
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
  },
  mounted () {
    this.initObserver()
  },
  updated () {
    this.observer.disconnect()
    this.sendedJobs = []
    this.$nextTick(() => this.initObserver())
  },
  beforeDestroy () {
    if (process.client) {
      this.destroyObserver()
    }
  },
  methods: {
    initObserver () {
      const options = {
        rootMargin: '0px',
        threshold: 0.90
      }
      if (!this.observer) {
        this.observer = new IntersectionObserver(this.onVisible, options)
      }
      this.$nextTick(() => this.observeJobs())
    },
    observeJobs () {
      if (this.$refs.job) {
        const jobs = Array.isArray(this.$refs.job) ? this.$refs.job : [this.$refs.job]
        jobs.forEach((job: any, i: number) => {
          if (!this.sendedJobs.includes(this.jobs[i].id)) {
            this.observer.observe(job.$el || job)
          }
        })
      }
    },
    destroyObserver () {
      if (this.observer) {
        this.observer.disconnect()
        this.observer = null
      }
    },
    onVisible (entries: any) {
      const jobsNotification = []
      for (const entry of entries) {
        if (entry.intersectionRatio > 0.9) {
          const index = this.$refs.job.findIndex((job: any) => entry.target === (job.$el || job))
          if (this.jobs[index] && !this.sendedJobs.includes(this.jobs[index].id)) {
            this.sendedJobs.push(this.jobs[index].id)
            jobsNotification.push({ job: this.jobs[index], index })
            if (this.observer) {
              this.observer.unobserve(entry.target)
            }
          }
        }
      }
      if (jobsNotification.length > 0) {
        this.notificateAboutJobs(jobsNotification)
      }
    },
    notificateAboutJobs (jobs: Array<any>) {
      const event = {
        event: 'impressions',
        ecommerce: {
          currencyCode: 'USD',
          impressions: jobs.map(job => {
            const category = this.getCategory(job.job)
            return {
              name: job.job.name,
              id: `${job.job.id}`,
              price: formatUsd(job.job.budget),
              category,
              brand: 'Jobs',
              list: this.$route.fullPath,
              position: job.index + 1,
            }
          }),
        }
      }
      googleAnalyticsV2.send(event)
    },
    getCategory (job: any) {
      if (this.skillDetails?.name) {
        return this.skillDetails?.name
      }
      if (!job.skills || job.skills.length === 0) {
        return ''
      }
      return job.skills[0].name
    },
    isOwnJob (job: JobListItem) {
      return this.userId === job.user.id
    },
  }
})
