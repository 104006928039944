import Vue, { PropType } from 'vue'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import Skill from '@/models-ts/Skill'
import skillableMixin from '@/mixins/skillableMixin'
import { mapGetters } from 'vuex'
import Job from '@/models-ts/job/Job'

export default Vue.extend<any, any, any, any>({
  mixins: [skillableMixin],
  props: {
    loading: Boolean,
    disabled: Boolean,
    readOnly: Boolean,
    value: {
      type: Array as PropType<Array<Skill>>,
      default: () => [],
    },
    isEdit: Boolean,
    job: Job,
  },
  computed: {
    ...mapGetters({
      categories: 'skills/getCategories',
      getRoots: 'skills/getRoots',
      getChilds: 'skills/getChilds'
    }),
    category () {
      return this.value.find((skill: Skill) => !skill.relations?.Parent.length)
    },
    options () {
      if (this.category) {
        return this.getChilds(this.category.id)
      }
      return this.predefinedSkills || []
    },
    valueSkills () {
      if (!this.loading && this.value) {
        return this.value.map((skill: Skill) => this.predefinedSkills.find((s: Skill) => s.id === skill.id))
      }
      return []
    },
    selectedTags (): CloudyTag {
      return this.value.map((s: Skill) => ({ text: s.name }))
    },
    recommendedTags () {
      let skills = this.categories
      if (this.category) {
        skills = this.options.filter((opt: Skill) => !this.value.find((skill: Skill) => skill.id === opt.id))
      }
      return skills.map((s: Skill) => ({ text: s.name }))
    },
  },
  methods: {
    addSkill (skill: Skill) {
      if (!this.disabled) {
        const skills: Array<Skill> = this.value || []
        if (!skills.find(s => s.id === skill.id)) {
          const newSkills = []
          if (!this.category && skill.relations?.Parent.length) {
            const parents = this.getRoots(skill)
            const category = parents.find((p: Skill) => !p.relations?.Parent.length && !this.value.find((s: Skill) => s.id === p.id))
            if (category) {
              newSkills.push(category)
            }
          }
          newSkills.push(skill)
          this.$emit('input', skills.concat(newSkills))
          const params = {
            event: 'category-selected',
            name: skill.name,
          }
          if (this.isEdit) {
            // @ts-ignore
            params.job_id = this.job.id
          }
          googleAnalyticsV2.send(params)
        }
      }
    },
    onInput (skill: Skill) {
      this.addSkill(skill)
    },
    onDelete ({ tag }: { tag: CloudyTag }) {
      if (!this.disabled) {
        const index = this.value.findIndex((s: Skill) => s.name === tag.text)
        if (index !== -1) {
          let skills = [...this.value]
          const skill: Skill = this.value[index]
          skills.splice(index, 1)
          if (this.category?.name === skill.name) {
            const childs = this.getChilds(skill.id)
            skills = skills.filter(s => !childs.find((c: Skill) => c.id === s.id))
          }
          this.$emit('input', skills)
        }
      }
    },
    onRecommendedClick ({ tag }: { tag: CloudyTag }) {
      const skill = this.predefinedSkills.find((s: Skill) => s.name === tag.text)
      if (skill) {
        this.addSkill(skill)
      }
    },
    validate (publishing: boolean) {
      this.errors.clear()
      if (publishing || this.value?.length) {
        const isValid = this.value?.length
        if (!isValid) {
          this.errors?.add({
            field: 'value',
            msg: `The Skills field is required`
          })
        }
        return isValid
      }
      return true
    },
  },
})
