import { render, staticRenderFns } from "./TalentCard.htm?vue&type=template&id=33e9448b&scoped=true&"
import script from "./TalentCard.ts?vue&type=script&lang=js&"
export * from "./TalentCard.ts?vue&type=script&lang=js&"
import style0 from "./TalentCard.scss?vue&type=style&index=0&id=33e9448b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33e9448b",
  null
  
)

export default component.exports