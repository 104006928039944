import Vue from 'vue'
// @ts-ignore
import NoSsr from 'vue-no-ssr'
import { mapGetters, mapActions } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import shareableMixin from '@/mixins/shareableMixin'
import { BROWSE_JOBS_BY_SKILL } from '@/constants/routes'
import { formatDescription } from '@/utils-ts/strings'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { formatDate, getHumanDate } from '@/utils/date'
import { isImage } from '@/utils/file'
import File from '@/models-ts/File'
import { FileCategories } from '@/constants/backend/FileCategories'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, shareableMixin],
  components: {
    NoSsr,
  },
  data () {
    return {
      mobileMoreSkillsOpened: false,
    }
  },
  computed: {
    ...mapGetters({
      job: 'jobDetails/job',
    }),
    publishDate () {
      return getHumanDate(this.job.edited_at)
    },
    formattedPublishDate () {
      return formatDate(this.job.edited_at, '')
    },
    category () {
      return this.job.meta.category
    },
    description () {
      return formatDescription(this.job.description)
    },
    files () {
      return this.job.relations.File.filter((file: File) => file.category !== FileCategories.CAT_GENERATED_PDF)
    },
    skillsTags () {
      const categorySkill = this.category
        ? {
          text: this.category.name,
          link: {
            name: BROWSE_JOBS_BY_SKILL,
            params: { skill: this.category.url },
          }
        }
        : null
      const skills: Array<CloudyTag> = this.job.relations.Skill
        .filter((skill: any) => skill.id !== this.category?.id)
        .map((skill: any) => ({
          text: skill.name,
          link: {
            name: BROWSE_JOBS_BY_SKILL,
            params: { skill: skill.url },
            ...(!skill.is_custom && skill.url && { link: { name: BROWSE_JOBS_BY_SKILL, params: { skill: skill.url } } })
          }
        }))
      if (categorySkill) {
        skills.unshift(categorySkill)
      }
      return skills
    },
  },
  methods: {
    getHumanDate,
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onClickImage (index: number) {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ImageViewer/ImageViewer.vue'),
          props: {
            images: this.files.filter((file: File) => isImage(file)),
            initialImage: index,
          },
        }
      })
    },
    onOpenProfile () {
      googleAnalyticsV2.send({
        event: 'gig-click-on-user',
        'event-content': 'gig-page-body',
        job_id: this.job.id,
      })
    },
    onOpenByCategory () {
      googleAnalyticsV2.send({
        event: 'gig-click-on-category',
        name: 'Blockchain & Crypto',
        job_id: this.job.id,
      })
    },
    onClickDescriptionLink (e: any) {
      const link = e.target
      if (link && (link.nodeName === 'A' || link.nodeName === 'a')) {
        googleAnalyticsV2.send({
          event: 'gig-click-on-external-link',
          name: link.href,
          job_id: this.job.id,
        })
      }
    },
    onClickSkill (skill: any) {
      googleAnalyticsV2.send({
        event: 'gig-click-on-skill',
        name: skill.text,
        job_id: this.job.id,
      })
    },
  },
})
