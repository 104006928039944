import Vue from 'vue'
import { mapGetters } from 'vuex'
import Job from '@/models-ts/job/Job'
import {
  STAGE_NEW,
  STAGE_STARTED,
  STAGE_IN_PROGRESS,
  STAGE_COMPLETED,
  STAGE_DEADLINE_OVERDUE,
  STAGE_BLOCKED_BY_FREELANCER,
  STAGE_DISPUTED,
} from '@/constants/job/jobStages'
import { STATUS_DRAFT, STATUS_PUBLISHED } from '@/constants/job/jobStatuses'
import { ModerationStages } from '@/constants/backend/ModerationStages'

const STEPS = [
  {
    name: 'Post Your Job',
    isCurrentStep (job: Job) {
      return job.status === STATUS_DRAFT || (job.status === STATUS_PUBLISHED && job.moderation_stage !== ModerationStages.PASSED)
    }
  },
  {
    name: 'Select Your Talent',
    isCurrentStep (job: Job) {
      return job.stage === STAGE_NEW &&
        job.status === STATUS_PUBLISHED &&
        job.moderation_stage === ModerationStages.PASSED &&
        !job.escrow_balance
    }
  },
  {
    name: 'Deposit In Escrow',
    isCurrentStep (job: Job) {
      return job.stage === STAGE_STARTED && !job.escrow_balance
    }
  },
  {
    name: 'Wait Until Job Is Done',
    isCurrentStep (job: Job) {
      return (job.stage === STAGE_IN_PROGRESS && !job.isDone) || job.stage === STAGE_BLOCKED_BY_FREELANCER
    }
  },
  {
    name: 'Accept Job & Pay',
    isCurrentStep (job: Job) {
      return job.stage === STAGE_IN_PROGRESS && job.isDone
    }
  },
  {
    name: 'Leave A Review',
    isCurrentStep (job: Job) {
      const reviews = job.relations?.Review || []
      const hasReview = reviews.find(review => review.user?.id === job.customer_id)
      return (job.stage === STAGE_COMPLETED || job.stage === STAGE_DEADLINE_OVERDUE || job.stage === STAGE_DISPUTED) && !hasReview
    }
  },
]

export default Vue.extend({
  computed: {
    ...mapGetters({
      job: 'jobDetails/job',
    }),
    steps () {
      return STEPS.map(step => step.name)
    },
    currentStepIndex () {
      const index = STEPS.findIndex(step => step.isCurrentStep(this.job))
      return index === -1 ? STEPS.length : index
    },
  },
})
