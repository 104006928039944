import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import InfiniteLoading from 'vue-infinite-loading'
import TalentCard from './TalentCard/TalentCard.vue'
import VacancyRateTalent from '@/partials/VacancyRateTalent/VacancyRateTalent.vue'
import snackMixin from '@/mixins/snackMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, snackMixin],
  components: {
    TalentCard,
    VacancyRateTalent,
    InfiniteLoading,
  },
  data () {
    return {
      feedBackSentSuccess: false
    }
  },
  async created () {
    try {
      await this.load()
    } catch (err) {
      this.openSnackbar({
        type: this.SnackTypes.FAILURE,
        text: 'Error fetching recommendations. Please try again.',
      })
    }
  },
  computed: {
    ...mapState('vacancyDetails', {
      closedRateTalentModals: (state: any) => state.closedRateTalentModals,
      recommendations: (state: any) => state.aiRecommendations.values,
      isLoading: (state: any) => state.aiRecommendations.isLoading,
      isLoaded: (state: any) => state.aiRecommendations.isLoaded,
    }),
    ...mapGetters({
      vacancy: 'vacancyDetails/vacancy',
      vacancyId: 'vacancyDetails/vacancyId',
    }),
    isFeedbackActive () {
      return !this.vacancy.isCvMatchFeedbackSent && this.recommendations.length > 0
    },
    isFeedbackOpenedByModal () {
      return !this.closedRateTalentModals[this.vacancyId]
    }
  },
  methods: {
    ...mapMutations({
      setClosedRateTalentModal: 'vacancyDetails/setClosedRateTalentModal',
      setIsCvMatchFeedbackSent: 'vacancyDetails/setIsCvMatchFeedbackSent',
    }),
    ...mapActions({
      load: 'vacancyDetails/loadAIRecommendations',
      openModal: 'ui/openModal',
      closeModal: 'ui/closeModal',
    }),
    onPageEnd () {
      if (this.isFeedbackActive && this.isFeedbackOpenedByModal) {
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            title: 'Rate Talent selected for you',
            factory: import(/* webpackChunkName: "jobs-modals" */ '@/modals/RateTalent/RateTalent.vue'),
            props: {
              vacancy: this.vacancy
            }
          },
          listeners: {
            close: () => {
              this.onCloseRateTalent()
            },
            success: () => {
              this.setIsCvMatchFeedbackSent({ flag: true, vacancyId: Number(this.vacancyId) })
              this.closeModal()
              this.openModal({
                component: 'lx-success-modal',
                props: {
                  text: 'Thank you for your feedback!',
                },
              })
            },
          },
        })
      }
    },
    onCloseRateTalent () {
      this.setClosedRateTalentModal(this.vacancyId)
    },
    onSuccessFeedBackSent () {
      this.setIsCvMatchFeedbackSent({ flag: true, vacancyId: Number(this.vacancyId) })
      this.feedBackSentSuccess = true
      this.openModal({
        component: 'lx-success-modal',
        props: {
          text: 'Thank you for your feedback!',
        },
      })
    },
  },
})
