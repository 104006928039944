import Vue from 'vue'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { Statuses } from '@/constants/vacancies/statuses'
import {
  VACANCY_DETAILS,
  VACANCY_DETAILS_ADMIN,
  VACANCY_DETAILS_ADMIN_DESCRIPTION,
  VACANCY_DETAILS_ADMIN_APPLICATIONS,
  VACANCIES_MY,
  VACANCY_DETAILS_ADMIN_AI_RECOMMENDED,
} from '@/constants/routes'
import { PositionType, POSITION_TYPES } from '@/constants/vacancies/positionTypes'
import responseMixin from '@/mixins/responseMixin'
import rolebleMixin from '@/mixins/rolebleMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import ErrorMatcher from '@/utils/ErrorMatcher'
import JobDescription from '../components/JobDescription/JobDescription.vue'
import CustomerInfo from '../components/CustomerInfo/CustomerInfo.vue'
import ShareBlock from '../components/ShareBlock/ShareBlock.vue'
import ApplicationsList from '../components/ApplicationsList/ApplicationsList.vue'
import AIRecommendationsList from '../components/AIRecommendationsList/AIRecommendationsList.vue'
import MobileActionsCard from '../components/MobileActionsCard/MobileActionsCard.vue'
import FreelancerMoreSection from '../components/FreelancerMoreSection/FreelancerMoreSection.vue'
import AdminActionsCard from '../components/AdminActionsCard/AdminActionsCard.vue'
import VacancyApplicationItem from '@/models-ts/vacancies/VacancyApplicationItem'
import { RootState } from '@/store'
import VacancyStatusBadge from '@/partials/StatusBadges/VacancyStatusBadge/VacancyStatusBadge.vue'
import { aiAssistant } from '@/servicies-ts/AIAssistantService'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, rolebleMixin, canonicalMixin],
  components: {
    AdminActionsCard,
    ApplicationsList,
    AIRecommendationsList,
    CustomerInfo,
    JobDescription,
    FreelancerMoreSection,
    MobileActionsCard,
    ShareBlock,
    VacancyStatusBadge,
  },
  data () {
    return {
      isDescriptionActive: true,
      VACANCY_DETAILS_ADMIN_DESCRIPTION,
      VACANCY_DETAILS_ADMIN_APPLICATIONS,
      VACANCY_DETAILS_ADMIN_AI_RECOMMENDED,
      VACANCIES_MY,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      prefetched: (state: RootState) => state.vacancyDetails.prefetched,
      userIsLoaded: (state: RootState):boolean => state.user?.profile.isLoaded,
    }),
    ...mapGetters({
      vacancy: 'vacancyDetails/vacancy',
      vacancyId: 'vacancyDetails/vacancyId',
      isLoading: 'vacancyDetails/isLoading',
      isLoaded: 'vacancyDetails/isLoaded',
      isOwner: 'vacancyDetails/isOwner',
    }),
    positionType () {
      return POSITION_TYPES[this.vacancy.position_type as PositionType]?.name
    },
    remoteInfo () {
      return this.vacancy.remoteInfo
    },
    pageIsLoaded () {
      return this.isLoaded && this.userIsLoaded
    },
    isPublished () {
      return this.vacancy.status === Statuses.PUBLISHED
    },
    name () {
      return this.vacancy?.name
    },
    customerName () {
      return this.vacancy?.relations?.Customer?.name
    },
    visibleApplications () {
      return (this.vacancy.applications || [])
        .filter((app: VacancyApplicationItem) => app.isActive)
    },
    appCount () {
      if (this.isOwner && this.isLoggedIn) {
        const count = this.visibleApplications.length
        return count > 99 ? '99+' : count
      }
    },
    hasNewApp () {
      return this.visibleApplications.find((app: VacancyApplicationItem) => !app.isRead)
    },
    hasOffersCard () {
      return this.vacancy.first_published_at && this.isOwner && this.isLoggedIn
    },
    metaTitle () {
      return this.vacancy
        ? `${this.name} Job at ${this.customerName || 'Customer'}, ${this.positionType}, ${this.remoteInfo}.`
        : 'Loading'
    },
    metaDescription () {
      return this.vacancy
        // eslint-disable-next-line max-len
        ? `Apply for ${this.name} full time job at ${this.customerName || 'Customer'}. Find a Cryptocurrency job at LaborX and get paid in crypto.`
        : 'Loading'
    },
  },
  watch: {
    '$route': {
      handler () {
        this.isDescriptionActive = this.$route.name === VACANCY_DETAILS_ADMIN_DESCRIPTION ||
          this.$route.name === VACANCY_DETAILS_ADMIN
        if (!this.isDescriptionActive) {
          googleAnalyticsV2.send({
            event: 'ft-job-apps-view',
            ftj_id: this.vacancy.id,
          })
          googleAnalyticsV2.send({
            event: 'ftj-applications-view',
            ftj_id: this.vacancy.id,
          })
        }
      },
      immediate: true,
    },
    pageIsLoaded () {
      if (this.pageIsLoaded) {
        if (!this.isOwner || this.isFreelancer) {
          this.$router.replace({ name: VACANCY_DETAILS, params: { id: this.vacancy.id, slug: this.vacancy.slug } })
        }
      }
    },
  },
  async created () {
    try {
      if (String(this.vacancy?.id) !== String(this.vacancyId)) {
        await Promise.all([
          this.load({ id: this.$route.params.id, slug: this.$route.params.slug }),
          this.getCountries()
        ])
      }
    } catch (e) {
      if (ErrorMatcher.isGone(e) || ErrorMatcher.isNotFound(e) || ErrorMatcher.isForbidden(e) || ErrorMatcher.isConflict(e)) {
        this.setNotFound(true)
      } else {
        this.parseError(e)
      }
    }
  },
  mounted () {
    googleAnalyticsV2.send({
      event: 'ftj-view',
      ftj_id: this.vacancy?.id,
    })
  },
  beforeDestroy () {
    if (!this.vacancyId) {
      this.clearVacancy()
    }
    aiAssistant.destroy()
  },
  methods: {
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      clearVacancy: 'vacancyDetails/clearVacancy',
    }),
    ...mapActions({
      load: 'vacancyDetails/load',
      getCountries: 'countries/getCountries',
    }),
  },
  metaInfo () {
    const title = this.metaTitle
    const meta = [
      {
        name: 'description',
        content: this.metaDescription,
      },
    ]
    return {
      title,
      meta,
      link: [this.canonicalLink],
    }
  },
})
