import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'
// @ts-ignore
import NoSsr from 'vue-no-ssr'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { BROWSE_JOBS_BY_SKILL } from '@/constants/routes'
import { withCommas } from '@/utils/moneyFormat'
import { addMonths, formatDate } from '@/utils/date'
import { stripDescriptionTags } from '@/utils-ts/strings'
import Skill from '@/models-ts/Skill'
import { META_COUNTRIES } from '@/constants/meta'
import { DATE_MONTH_YEAR_FORMAT, DATE_TIME_FORMAT_WITHOUT_SEC } from '@/constants/utils/date'
import SeoQuestions from '@/partials/SeoQuestions/SeoQuestions.vue'
import lxAnalytics from '@/servicies-ts/analytics/LxAnalytics'
import responseMixin from '@/mixins/responseMixin'
import seoSkillMixin from '@/mixins/seoSkillMixin'
import shareableMixin from '@/mixins/shareableMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import FilterCard from './FilterCrad/FilterCard.vue'
import { MAX_BUDGET, MIN_BUDGET, RATING_OPTIONS, SORT_OPTIONS } from './FilterCrad/FilterCard'
import JobsLoader from './JobsLoader/JobsLoader.vue'
import JobsPalette from './JobsPalette/JobsPalette.vue'
import skillMixins from './mixins/skillMixins'
import questions from './questions'
import { RootState } from '@/store'
import { SeoSkillEntityTypes } from '@/constants/backend/SeoSkill'

const JOBS_LIMIT = 30

const POPULAR_SKILLS = [
  'development',
  'blockchain-and-crypto',
  'design-and-creative',
  'nft',
  'writing',
  'sales-and-marketing',
]
const AVG_SKILLS = [
  'blockchain-and-crypto',
  'design-and-creative',
  'sales-and-marketing',
  'writing',
  'development',
  'nft',
  'gaming',
  'audio-and-video',
]

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, shareableMixin, seoSkillMixin, skillMixins, canonicalMixin],
  components: {
    InfiniteLoading,
    NoSsr,
    JobsLoader,
    JobsPalette,
    FilterCard,
    SeoQuestions,
  },
  data () {
    return {
      questions,
      totalLoading: false,
      processing: false,
      infiniteId: 0,
      filtersCount: 0,
    }
  },
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
      prefetched: (state: RootState) => state.browseJobs.prefetched,
      isLoaded: (state: RootState) => state.browseJobs.jobs.isLoaded,
      jobsPreloading: (state: RootState) => state.browseJobs.jobs.isLoading,
      pagination: (state: RootState) => state.browseJobs.jobs.pagination,
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      jobs: 'browseJobs/jobs',
    }),
    byCategory () {
      return this.$route.name === BROWSE_JOBS_BY_SKILL
    },
    total () {
      return this.pagination.total
    },
    totalFoundText () {
      return this.skillDetails?.name
        ? `Check out ${this.total} posted ${this.skillDetails.name} Jobs.
        At LaborX you have a great option to choose a freelance job for ${this.skillDetails.name}
        Apply for a job now and get paid in cryptocurrency.`
        : `${this.total} Job${this.total === 1 ? '' : 's'} found`
    },
    popularSkills () {
      return POPULAR_SKILLS
        .map((url: string) => {
          return this.predefinedSkills.find((sk: any) => sk.url === url)
        })
        .filter(Boolean)
        .map((skill: any) => ({
          text: skill.name,
          link: { name: BROWSE_JOBS_BY_SKILL, params: { skill: skill.url } }
        }))
    },
    avgSkills () {
      return AVG_SKILLS
        .map((url: string) => {
          return this.predefinedSkills.find((sk: any) => sk.url === url)
        })
        .filter(Boolean)
        .sort((a: any, b: any) => b.level === a.level ? b.id - a.id : b.level - a.level)
        .map((skill: any) => ({
          text: skill.name,
          link: { name: BROWSE_JOBS_BY_SKILL, params: { skill: skill.url } }
        }))
    },
    hasAvgReviews () {
      return this.skillDetails?.freelancers_score_based_on > 0
    },
    pageTitle () {
      return this.skillDetails?.name ? `Find Freelance ${this.skillDetails.name} Jobs` : 'Browse Jobs'
    },
    seoDescription () {
      if (!this.isLoaded) {
        return null
      }
      if (this.skillDetails?.name) {
        return this.getSeoText(
          this.skill,
          SeoSkillEntityTypes.JOB,
          `We found ${this.total} Freelance ${this.skillDetails.name} Job${this.total !== 1 ? 's' : ''} that paid in cryptocurrency.
Search for work, find a remote job that suits your needs, and apply now!`
        )
      }
      return `We found ${this.total} Freelance Blockchain and Crypto job${this.total !== 1 ? 's' : ''} that paid in cryptocurrency.
Search for work, find a remote job that suits your needs, and apply now!`
    },
    metaTitle () {
      if (!this.isLoaded) {
        return 'Loading'
      }
      if (!this.skillDetails) {
        return 'WEB3 Jobs, Remote Blockchain Jobs list'
      } else {
        const monthDate = formatDate(new Date(), DATE_MONTH_YEAR_FORMAT)
        const countJobs = this.skillDetails.meta?.previous_month_count?.jobs
        const postfix = countJobs
          ? ` in ${monthDate} (${this.skillDetails.meta?.previous_month_count?.jobs} NEW)`
          : ''
        return this.getSeoTitle(
          this.skillDetails,
          SeoSkillEntityTypes.JOB,
          `Freelance ${this.skillDetails.name} Jobs${postfix}`,
        )
      }
    },
    metaDescription () {
      if (!this.skillDetails) {
        return 'Find and apply for WEB3 and Crypto jobs now. Sign contract. Complete task. Get paid in cryptocurrency'
      }
      return this.getSeoDescription(
        this.skillDetails,
        SeoSkillEntityTypes.JOB,
        // eslint-disable-next-line max-len
        `★ Looking for ${this.skillDetails.name} jobs? ➔ Choose out of best projects in ${formatDate(Date.now(), 'MMMM YYYY')} ➔ Find and apply to ${this.skillDetails.name} jobs now on LaborX`
      )
    },
  },
  watch: {
    async $route (newR, oldR) {
      if (newR.query !== oldR.query) {
        this.reloadJobs()
        lxAnalytics.send('browse-jobs', this.getFilterFromQuery())
      }
    },
  },
  async prefetch () {
    if (process.server) {
      const skillUrl = this.$route.params.skill
      let predefinedSkills = []
      try {
        predefinedSkills = await this.getSkills()
      } catch (e) {
        console.error('Error fetching jobs:', e)
      }
      if (skillUrl) {
        if (!predefinedSkills.find((skill: Skill) => skill.url === skillUrl)) {
          this.setNotFound(true)
          this.setPrefetched(true)
          return
        }
        if (this.skillDetails.change_id) {
          const newSkill = predefinedSkills.find((skill: Skill) => skill.id === this.skillDetails.change_id)
          if (newSkill) {
            this.setRedirect(this.$route.fullPath.replace(this.skillDetails.url, newSkill.url))
            return
          }
        }
        if (this.skillDetails.is_removed) {
          this.setRedirect('/jobs')
          return
        }
      }
      await this.loadJobs(this.getFilterFromQuery())
      this.setPrefetched(true)
      this.$store.commit('responseHeaders/disableCacheControl')
    }
  },
  async created () {
    if (process.client) {
      try {
        await this.getSkills()
        if (!this.prefetched) {
          this.setPagination({ limit: JOBS_LIMIT, offset: 0 })
          await this.loadJobs(this.getFilterFromQuery())
        } else {
          this.getFilterFromQuery()
        }
        this.infiniteId = +new Date()
        this.setPrefetched(false)
        lxAnalytics.send('browse-jobs', this.getFilterFromQuery())
      } catch (e) {
        this.parseError(e)
      }
    }
  },
  methods: {
    withCommas,
    ...mapActions({
      openModal: 'ui/openModal',
      getSkills: 'skills/getSkills',
      loadJobs: 'browseJobs/loadJobs',
      loadMoreJobs: 'browseJobs/loadMoreJobs',
    }),
    ...mapMutations({
      setNotFound: 'app/setNotFound',
      setRedirect: 'app/setRedirect',
      setPrefetched: 'browseJobs/setPrefetched',
      setPagination: 'browseJobs/setPagination',
    }),
    async reloadJobs () {
      this.setPagination({ limit: JOBS_LIMIT, offset: 0 })
      await this.loadJobs(this.getFilterFromQuery())
      this.$nextTick(() => this.infiniteId++)
    },
    async onLoadMore ($state: any) {
      try {
        if (this.jobs.length < this.pagination.total) {
          this.totalLoading = true
          this.setPagination({ limit: JOBS_LIMIT, offset: this.jobs.length })
          await this.loadMoreJobs(this.getFilterFromQuery())
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.totalLoading = false
      }
    },
    getFilterFromQuery () {
      const {
        search,
        sort = null,
        rating,
        budgetFrom,
        skill = [],
        budgetTo
      } = this.$route.query
      const sortValue = !sort ? SORT_OPTIONS[0] : SORT_OPTIONS.find((opt: any) => opt.queryValue === sort)
      const ratingValue = RATING_OPTIONS.find((opt: any) => opt.value === +rating)
      const bFrom = (Number.isNaN(+budgetFrom) || +budgetFrom === MIN_BUDGET) ? null : +budgetFrom
      const bTo = (Number.isNaN(+budgetTo) || +budgetTo === MAX_BUDGET) ? null : +budgetTo
      let skillsValue = [] as Array<Skill['id']>
      if (this.$route.params.skill) {
        const mainSkill = this.predefinedSkills.find((opt: any) => opt.url === this.$route.params.skill)
        if (mainSkill) {
          skillsValue.push(mainSkill.id)
        }
      }
      let skillsArray = Array.isArray(skill) ? skill : [skill]
      skillsValue = skillsValue.concat(skillsArray
        .map(id => this.predefinedSkills.find((opt: any) => +opt.id === +id)?.id)
        .filter(Boolean)
      )
      skillsValue = Array.from(new Set(skillsValue))
      this.filtersCount = 0 + (search ? 1 : 0) +
        (ratingValue ? 1 : 0) +
        (bFrom ? 1 : 0) +
        (bTo ? 1 : 0) +
        (skillsValue.length > 0 ? 1 : 0)
      return {
        search: search?.trim(),
        skills: skillsValue,
        sort: sortValue?.value,
        direction: sortValue?.direction || null,
        avgReviews: ratingValue?.value,
        rateFrom: bFrom,
        rateTo: bTo,
      }
    },
    onClickOpenFilters () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "jobs-modals" */ './FilterModal/FilterModal.vue'),
          title: 'Filters',
          props: {
          }
        }
      })
    },
    onMereSkillClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "jobs-modals" */ '@/modals/NavigationBySkills/NavigationBySkills.vue'),
          title: 'Categories',
        }
      })
    },
  },
  metaInfo () {
    const script = []
    const getBudgetUnit = (budget: number) => {
      if (budget < 50) {
        return 'HOUR'
      } else if (budget < 200) {
        return 'DAY'
      } else if (budget < 1000) {
        return 'WEEK'
      }
      return 'MONTH'
    }
    for (let job of this.jobs) {
      script.push({
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'JobPosting',
          title: job.name,
          description: stripDescriptionTags(job.description, { stripLinks: true }),
          datePosted: formatDate(job.publishedAt, 'YYYY-MM-DD'),
          employmentType: 'CONTRACTOR',
          jobLocationType: 'TELECOMMUTE',
          applicantLocationRequirements: META_COUNTRIES.map(name => ({
            '@type': 'Country', name
          })),
          hiringOrganization: {
            '@type': 'Organization',
            name: job.user.name,
            ...(job.user.avatar?.src && { logo: job.user.avatar.src }),
            ...(job.userWebsite && { sameAs: job.userWebsite }),
          },
          occupationalCategory: job.skills.length > 0 ? job.skills[0].name : '',
          ...(job.skills.length > 1 && { skills: job.skills.slice(1).map((s: Skill) => s.name).join(', ') }),
          estimatedSalary: {
            '@type': 'MonetaryAmount',
            currency: 'USD',
            value: {
              '@type': 'QuantitativeValue',
              value: job.budget,
              unitText: getBudgetUnit(Number(job.budget)),
            },
          },
          validThrough: formatDate(addMonths(job.publishedAt, 6), DATE_TIME_FORMAT_WITHOUT_SEC),
        }
      })
    }
    return {
      title: this.metaTitle,
      meta: this.metaDescription ? [
        { name: 'description', content: this.metaDescription },
      ] : [],
      script,
      link: [this.canonicalLink]
    }
  },
})
